import(/* webpackMode: "eager" */ "/var/lib/jenkins/workspace/SynergieCareers - Live/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/shared/components/blog/BlogpostTeaser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/shared/components/EventBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/shared/components/EventTeaser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/shared/components/FirstJobBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/shared/components/IndexCompanies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/shared/components/IndexJobSearchForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/synergiecareers.be/components/AppLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/lib/jenkins/workspace/SynergieCareers - Live/synergiecareers.be/components/IntroText.tsx");
