'use client';

import Link from 'next/link';
import { useParams } from 'next/navigation';
import { Row, Container, Col } from 'reactstrap';
import CompanyTile from './CompanyTile';
import { getI18nUtils } from '../i18n/i18n-utils';
import { Locale } from '../i18n/i18n-config';

function IndexCompanies({ companies, dictionary }) {
  const { formatMessage } = getI18nUtils(dictionary);
  const params = useParams();
  const { locale } = params as { locale: Locale };

  return (
    <Container className="indexCompanies py-5" tag="section">
      {Array.isArray(companies) && companies.length > 0 && (
        <>
          <Row className="mb-5">
            <Col md="6" xs="0">
              <h2 className="mb-0 text-center text-md-start">
                <span>{formatMessage({ id: 'index-companies-h2' })}</span>
              </h2>
            </Col>
            <Col md="6" className="text-end d-none d-md-block">
              <Link href={`/${locale}/companies/`}>{formatMessage({ id: 'index-companies-show-all' })}</Link>
            </Col>
          </Row>
          <Row>
            {companies &&
              companies.map((company, i) => (
                <CompanyTile locale={locale} company={company} key={`companyTile_${i}_${company.id}`} homepage />
              ))}
          </Row>
        </>
      )}
    </Container>
  );
}

export default IndexCompanies;
