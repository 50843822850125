'use client';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { Locale } from 'shared/i18n/i18n-config';

export default function IntroText() {
  const params = useParams<{ locale: Locale }>();
  const { locale }: { locale?: Locale } = params || {};

  switch (locale) {
    case 'fr':
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="mw-100">
            <p>
              Starter ou Senior ? Vous recherchez un projet temporaire ou plutôt un job permanent ? Chez Synergie Careers, vous
              trouverez l’opportunité qui vous convient.
            </p>
            <p>
              Consultez la liste de <Link href={`/${locale}/jobs/`}>nos postes vacants</Link> ou planifiez un entretien avec{' '}
              <Link href={`/${locale}/agencies`}>l’agence Synergie</Link> près de chez vous. A bientôt !
            </p>
          </div>
        </div>
      );
    case 'nl':
    default:
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="mw-100">
            <p>
              Starter of senior? Op zoek naar een tijdelijk project of liever een vaste job? Bij Synergie Careers vind je de job
              als bediende die bij jou past.
            </p>
            <p>
              Kijk in het overzicht met al <Link href={`/${locale}/jobs/`}>onze openstaande vacatures</Link> of plan een gesprek
              in met het <Link href={`/${locale}/agencies`}>Synergie-kantoor</Link> in jouw buurt. Tot snel!
            </p>
          </div>
        </div>
      );
  }
}
