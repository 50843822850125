'use client';

import { Container, Row, Col } from 'reactstrap';
import Link from 'next/link';
import CtaBtn from './CtaBtn';
import useWindowWidth from '../hooks/useWindowWidth';
import { Dictionaries } from '../i18n/i18n-config';
import { getI18nUtils } from '../i18n/i18n-utils';

export interface EventBanner {
  title: keyof Dictionaries;
  intro: keyof Dictionaries;
  backgroundUrl: string;
  ctaBtnLink: string;
  ctaBtnText: string;
  backgroundUrlMobile?: string;
  darkened?: boolean;
  dictionary: Partial<Dictionaries>;
  textPosition?: string;
}

const EventBanner = ({
  title,
  intro,
  backgroundUrl,
  ctaBtnLink,
  ctaBtnText,
  backgroundUrlMobile,
  darkened,
  dictionary,
  textPosition,
}: EventBanner): JSX.Element => {
  const { formatMessage } = getI18nUtils(dictionary);
  const windowWidth = useWindowWidth();
  const onMobile = windowWidth && windowWidth < 576;

  return (
    <div id="eventBanner">
      <Container fluid className="p-0 ">
        <Row
          className={`eventBannerContainer ${darkened ? 'darkened' : ''} `}
          style={{
            backgroundImage: `url(${onMobile && backgroundUrlMobile ? backgroundUrlMobile : backgroundUrl})`,
            backgroundPosition: 'center',
          }}
        >
          <Col md={{ size: 4, offset: 2 }} className="h-100 d-flex align-items-center">
            <div
              className={`textWrapper d-flex m-3 ${
                textPosition && textPosition === 'left' ? 'justify-content-start' : 'justify-content-center'
              }
            `}
            >
              <div className="textContainer d-flex flex-column justify-content-center m-2">
                <p className="mb-3 text-uppercase font-weight-light">{formatMessage({ id: intro })}</p>
                <h2 className="font-weight-bold">{formatMessage({ id: title })}</h2>
                <Link href={ctaBtnLink} rel="noopener noreferrer" target="_blank">
                  <CtaBtn className="cta red mt-3">{ctaBtnText}</CtaBtn>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventBanner;
