'use client';

import Link from 'next/link';
import { Card, CardBody, CardText } from 'reactstrap';
import dayjs from 'dayjs';
import useWindowWidth from 'shared/hooks/useWindowWidth';
import slugify from 'slugify';
import { useParams } from 'next/navigation';
import { getI18nUtils } from '../../i18n/i18n-utils';
import { Dictionaries } from '../../i18n/i18n-config';

function BlogpostTeaser({
  post,
  featured,
  wide,
  indexPage,
  dictionary,
}: {
  post?: {
    id: string;
    category: string;
    teaserImg: any;
    title: string;
    intro: string;
    created: string;
  };
  featured?: boolean;
  wide?: boolean;
  indexPage?: boolean;
  dictionary: Partial<Dictionaries>;
}) {
  const { formatMessage } = getI18nUtils(dictionary);
  const { locale } = useParams();
  const size = useWindowWidth();
  const { id, category, teaserImg, title, intro, created } = post || {};

  const href = title ? `/${locale}/blog/${id}/${slugify(title, { lower: true })}` : `${locale}/blog`;
  return (
    <Card className={`blogpostTeaser mb-3 shadow-sm ${category} ${wide ? 'wide' : ''} ${featured ? 'featured' : ''}`}>
      <div className={`imageContainer ${wide ? 'col-lg-4 ' : ''}`}>
        <Link href={href}>
          {featured || wide ? (
            <picture>
              <source
                media="(max-width: 991px)"
                srcSet={`${teaserImg.wide.small} 1x, ${teaserImg.wide.medium} 1.5x, ${teaserImg.wide.large} 2x`}
              />
              <source
                media="(min-width: 992px)"
                srcSet={`${teaserImg.square.small} 1x, ${teaserImg.square.medium} 1.5x, ${teaserImg.square.large} 2x`}
              />
              <img className="card-img" src={`${teaserImg.square.medium}`} alt={teaserImg.description} />
            </picture>
          ) : (
            <img
              className="card-img"
              srcSet={`${teaserImg.wide.small} 1x, ${teaserImg.wide.medium} 1.5x, ${teaserImg.wide.large} 2x`}
              src={teaserImg.wide.large}
              alt={teaserImg.description}
            />
          )}
        </Link>
      </div>
      <CardBody className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          {category && <span className={`category ${category}`}>{formatMessage({ id: category.trim().toLowerCase() })}</span>}
          {!indexPage && <span className="blogCreated">{dayjs(created).format('DD MMMM YYYY')}</span>}
        </div>
        <Link href={href}>
          <h3 className="card-title mt-2 mb-1 text-dark">{title}</h3>
        </Link>
        {intro && (
          <CardText className="flex-grow-1">{`${intro.substring(0, (featured || wide) && size && size > 992 ? 180 : 80)}...`}</CardText>
        )}
        <div className="d-flex justify-content-between align-items-center pt-2">
          <Link href={href}>{formatMessage({ id: 'post-read' })}</Link>
          <svg height="10" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="m-4-2h16v16h-16z" />
              <path
                d="m1.1117.02285-.8838.883775 4.7267 4.726525-4.7267 4.7265.8838.883775 5.610525-5.610275z"
                fill="#e2001a"
                className="arrowRightFill"
                fillRule="nonzero"
                transform="translate(.5 .25)"
              />
            </g>
          </svg>
        </div>
      </CardBody>
    </Card>
  );
}

export default BlogpostTeaser;
