'use client';

import React from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { Container, Row, Col } from 'reactstrap';
import CtaBtn from './CtaBtn';
import { getI18nUtils } from '../i18n/i18n-utils';

// commented temporary for ek-contest

const FirstJobBannerLink = ({ locale, children }) => {
  switch (process.env.NEXT_PUBLIC_APP) {
    case 'synergiecareers.be':
      return (
        <Link style={{ textDecoration: 'none' }} href={`/${locale}/candidates/my-first-job`}>
          {children}
        </Link>
      );
    default:
      return (
        <a
          target="_blank"
          href={`https://www.synergiecareers.be/${locale}/candidates/my-first-job`}
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          {children}
        </a>
      );
  }
};

const FirstJobBanner = ({ dictionary }) => {
  const { locale } = useParams();
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div id="firstJobBanner">
      <Container className="h-100">
        <FirstJobBannerLink locale={locale}>
          <Row className="h-100">
            <Col md={{ size: 4, offset: 7 }} className="h-100 d-flex align-items-center">
              <div className="bannerText text-white">
                <p className="mb-3 text-uppercase font-weight-light">{formatMessage({ id: 'hp-banner-graduated' })}</p>
                <h2 className="font-weight-bold">{formatMessage({ id: 'hp-banner-job-experience' })}</h2>
                <CtaBtn className="mt-3 cta red" type="button">
                  {formatMessage({ id: 'hp-banner-discover' })}
                </CtaBtn>
              </div>
            </Col>
          </Row>
        </FirstJobBannerLink>
      </Container>
    </div>
  );
};

export default FirstJobBanner;
