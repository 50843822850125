import { Col } from 'reactstrap';
import Link from 'next/link';
import slugify from 'slugify';
import { Locale } from '../i18n/i18n-config';

const CompanyTile = ({ company, homepage, locale }: { company: any; homepage?: boolean; locale: Locale }) => {
  const { logo, name, id } = company;
  return (
    <Col xs={12} md={6} className="mb-3">
      <div className="border companyTile h-100">
        <Link className="text-decoration-none" href={`/${locale}/companies/${id}/${slugify(name, { lower: true })}/`}>
          <div className="d-flex flex-column flex-lg-row">
            <img className="d-block mx-auto mx-lg-0 px-4 my-md-3 img-fluid" src={logo} alt={`${name} logo`} />

            <div className="companyName mx-auto mx-lg-3 flex-grow w-100 d-flex justify-content-between align-items-center py-4">
              {homepage ? (
                <h3 className="mb-0 me-2 text-uppercase text-muted ">{company.name}</h3>
              ) : (
                <h2 className="mb-0 me-2 text-uppercase text-muted ">{company.name}</h2>
              )}

              <svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg">
                <g fillRule="evenodd">
                  <path
                    className="fillColor"
                    d="m1.1117.02285-.8838.883775 4.7267 4.726525-4.7267 4.7265.8838.883775 5.610525-5.610275z"
                    fillRule="nonzero"
                    transform="translate(.5 .25)"
                  />
                </g>
              </svg>
            </div>
          </div>
        </Link>
      </div>
    </Col>
  );
};

export default CompanyTile;
